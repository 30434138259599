<template>
	<SectionLine class="pointer" @click="toggleCollapse">
		<slot />
		<ChevronDownIcon class="icon chevron-down" :class="{ 'chevron-down--up': isExpanded }" />
	</SectionLine>

	<Transition name="expand">
		<SectionLine v-show="isExpanded" ref="content" class="wrapper">
			<slot name="expanded" />
		</SectionLine>
	</Transition>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import SectionLine from './SectionLine.vue';
import ChevronDownIcon from '@/assets/icons/chevron-down.svg?component';

const isExpanded = ref<boolean>(false);
const height = ref();
const content = ref<HTMLDivElement>();

const toggleCollapse = () => {
	isExpanded.value = !isExpanded.value;
};

onMounted(() => {
	height.value = `${content.value!.getBoundingClientRect().height}px`;
});
</script>

<style scoped>
.expand-leave-active,
.expand-enter-active {
	transition: all 350ms ease;
	overflow: hidden;
}

.expand-enter-to,
.expand-leave-from {
	/* height: v-bind(height); */
}

.expand-enter-from,
.expand-leave-to {
	opacity: 0;

	/* height: 0; */
}

.chevron-down {
	transition: all 350ms;
}

.chevron-down--up {
	transform: rotate(0.5turn);
}
</style>
