<template>
	<div class="search-wrapper">
		<input
			:value="props.modelValue"
			v-bind="$attrs"
			@input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
		/>
		<SearchIcon class="search-icon" />
	</div>
</template>

<script setup lang="ts">
import SearchIcon from '@/assets/icons/search.svg?component';

const props = defineProps<{
	modelValue?: string;
}>();

const emit = defineEmits(['update:modelValue']);
</script>

<style>
.search-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: var(--secondary-background-color);
	border-radius: var(--size-xs);
}

.search-icon {
	max-height: 1.5em;
	margin-right: var(--size-xs);
}
</style>
