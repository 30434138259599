import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 50 50"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "25",
      cy: "25",
      r: "25",
      style: {"fill":"#e74c3c","stroke":"none"}
    }, null, -1),
    _createElementVNode("path", {
      d: "m15 15 20 20M35 15 15 35",
      style: {"stroke":"#fff","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","stroke-miterlimit":"10"}
    }, null, -1)
  ])))
}
export default { render: render }