<template>
	<img loading="lazy" :src="url" class="avatar" />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
//@ts-ignore
import UIAvatarSvg from 'ui-avatar-svg';
import { type IAsset } from '@/libs/asset';

const props = defineProps<{
	asset: IAsset;
}>();

const url = ref<string>();

watch(
	() => props.asset,
	() => {
		url.value = svgToPlain(props.asset.icon ? props.asset.icon : createSvgAvatar());
	},
	{
		immediate: true
	}
);

function createSvgAvatar(): string {
	return (
		new UIAvatarSvg()
			.text(props.asset.name[0].toUpperCase())
			//TODO color in config or css var()
			.bgColor('#0042CA')
			.textColor('#ffffff')
			.fontWeight(800)
			.generate()
	);
}

function svgToPlain(svg: string) {
	return 'data:image/svg+xml,' + svg.replace(/[<>#%{}"]/g, (x: string) => '%' + x.charCodeAt(0).toString(16));
}
</script>

<style scoped>
.avatar {
	border-radius: 50%;
}
</style>
