import { toRef, type Ref } from 'vue';

type TParams = Record<string, string | number | boolean>;
type THeaders = Record<string, string>;

export interface IBaseRequestOptions {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
    params?: TParams,
    body?: any,
    headers?: THeaders,
}

export type TRequestOptions = Omit<IBaseRequestOptions, 'params' | 'body' | 'method'>

export class ApiInstance {
    baseUrl: string;
    baseHeaders: Record<string, string>

    constructor(baseUrl: string, baseHeaders: THeaders = {
        'Content-Type': 'application/json'
    }) {
        this.baseUrl = baseUrl;
        this.baseHeaders = baseHeaders;
    }

    public setBaseUrl(baseUrl: string) {
        this.baseUrl = baseUrl;

        return this;
    }

    protected async request<T>(
        endpoint: string,
        { method = 'GET', params, body, headers }: IBaseRequestOptions = {}
    ): Promise<T> {
        const url = new URL(`${this.baseUrl}${endpoint}`);

        // console.log(url, {
        //     method,
        //     body: JSON.stringify(body),
        //     headers: {
        //         ...this.baseHeaders,
        //         ...headers
        //     }
        // })

        if (params) {
            for (const key of Object.keys(params)) {
                url.searchParams.append(key, params[key].toString());
            }
        }

        const req = await fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...this.baseHeaders,
                ...headers
            }
        });

        return req.json();
    }

    protected get<T>(endpoint: string, params?: TParams, options: TRequestOptions = {}): Promise<T> {
        return this.request<T>(endpoint, { ...options, params, method: 'GET' });
    }

    protected post<T>(endpoint: string, body?: any, options: TRequestOptions = {}): Promise<T> {
        return this.request<T>(endpoint, { ...options, body, method: 'POST' });
    }

    protected put<T>(endpoint: string, body?: any, options: TRequestOptions = {}): Promise<T> {
        return this.request<T>(endpoint, { ...options, body, method: 'PUT' });
    }

    protected delete<T>(endpoint: string, body?: any, options: TRequestOptions = {}): Promise<T> {
        return this.request<T>(endpoint, { ...options, body, method: 'DELETE' });
    }
}