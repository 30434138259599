import { useStorage } from '@/composables/useStorage';
import { useEncryptedStorage } from '@/composables/useStorageEncrypted';
import { useTelegram } from '@/composables/useTelegram';
import { SetSerializer } from '@/utils/serializer';
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue';
import { signTx, seedUtils, type TTxParams, type WithTxType } from '@waves/waves-transactions';
import { useRouter } from 'vue-router';
import { useModal } from './useModalStore';
import DecryptCloudStorageModal from '@/components/modals/DecryptCloudStorageModal.vue';

export const useStorePrivateMnemonicPhrases = defineStore('wallet-cloud-private-mnemonic-phrases', () => {
    const telegram = useTelegram();

    const mnemonicPhrasesCloudStorage = useEncryptedStorage('mnemonic_phrases', {
        storage: telegram.cloudStorage,
        serializer: SetSerializer,
    });

    return {
        ...mnemonicPhrasesCloudStorage
    }
});

export const useStorePrivatePublicKeys = defineStore('wallet-cloud-private-public-keys', () => {
    const telegram = useTelegram();

    const publicKeysCloudStorage = useStorage<Set<string>>('public_keys', {
        storage: telegram.cloudStorage,
        serializer: SetSerializer,
        defaults: new Set<string>(),
    });

    return {
        ...publicKeysCloudStorage
    }
});


export const useWalletCloudStore = defineStore('wallet-cloud', () => {
    const mnemonicPhrasesStore = useStorePrivateMnemonicPhrases();
    const publicKeysStore = useStorePrivatePublicKeys();

    const { data: publicKeys } = storeToRefs(publicKeysStore);
    const { data: mnemonicPhrases } = storeToRefs(mnemonicPhrasesStore);

    const isLoaded = computed(() => {
        return mnemonicPhrasesStore.isLoaded && publicKeysStore.isLoaded
    })

    const decryptBefore = (callback: () => any) => {
        if (mnemonicPhrasesStore.isEncrypted) {
            useModal({
                component: DecryptCloudStorageModal,
                isShow: true,
                onClose: (result?: boolean) => {
                    if (result === true) decryptBefore(callback);
                }
            })
        } else {
            callback();
        }
    }

    const addWallet = (mnemonicPhrase: string) => {
        decryptBefore(() => {
            if (!isLoaded.value) throw new Error('not loaded');

            if (publicKeysStore.data)
                publicKeysStore.data.add(seedUtils.Seed.fromExistingPhrase(mnemonicPhrase).keyPair.publicKey)
            else
                publicKeysStore.data = new Set(seedUtils.Seed.fromExistingPhrase(mnemonicPhrase).keyPair.publicKey)

            if (mnemonicPhrasesStore.data)
                mnemonicPhrasesStore.data.add(mnemonicPhrase);
            else
                mnemonicPhrasesStore.data = new Set([mnemonicPhrase])
        });
    };

    const addWallets = (mnemonicPhrases: string[]) => {
        decryptBefore(() => {
            if (!publicKeysStore.data || !mnemonicPhrasesStore.data) throw new Error('not loaded');

            publicKeysStore.data = new Set([...publicKeysStore.data, ...mnemonicPhrases.map(e => seedUtils.Seed.fromExistingPhrase(e).keyPair.publicKey)]);
            mnemonicPhrasesStore.data = new Set([...mnemonicPhrasesStore.data, ...mnemonicPhrases]);
        });
    };

    const removeWallet = (mnemonicPhrase: string) => {
        decryptBefore(() => {
            if (!publicKeysStore.data || !mnemonicPhrasesStore.data) throw new Error('not loaded');

            publicKeysStore.data.delete(seedUtils.Seed.fromExistingPhrase(mnemonicPhrase).keyPair.publicKey);
            mnemonicPhrasesStore.data.delete(mnemonicPhrase);
        });
    };

    const setPassword = (password: string) => {
        decryptBefore(() => {
            if (!publicKeysStore.data || !mnemonicPhrasesStore.data) throw new Error('not loaded');

            mnemonicPhrasesStore.setPassword(password);
        });
    };

    const create = (password: string) => {
        mnemonicPhrasesStore.setPassword(password);
        mnemonicPhrasesStore.data = new Set();
    }

    const clear = () => {
        mnemonicPhrasesStore.clear();
        publicKeysStore.data = new Set();
    }

    const { isEmpty, isEncrypted } = storeToRefs(mnemonicPhrasesStore);

    return {
        mnemonicPhrases,
        publicKeys,

        isLoaded,
        isEmpty,
        isEncrypted,

        addWallet,
        addWallets,
        removeWallet,
        create,
        clear,
        decrypt: mnemonicPhrasesStore.decrypt,
        setPassword,
        decryptBefore
    }
});