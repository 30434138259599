import { type Serializer, type StorageLike, type StorageLikeAsync } from '@vueuse/core';
import { computed, ref, watch, type Ref } from 'vue';
import { useStorage } from './useStorage';
import { Encription } from '@/utils/encryption';
import { DefaultSerializer } from '@/utils/serializer';

interface IUseEncryptedStorageOptions<T> {
    storage: StorageLike | StorageLikeAsync,
    serializer?: Serializer<T>,
}

const DEFAULT_PASSWORD = '';

export const useEncryptedStorage = <T>(key: string, options: IUseEncryptedStorageOptions<T>) => {
    const {
        storage = window.localStorage,
        serializer = DefaultSerializer,
    } = options;

    const { data: rawData, isLoaded } = useStorage<string>(key, { storage: storage });
    const password = ref<string>();

    const data = ref(null) as Ref<T | null>;

    watch([data, password], () => {
        if (!isLoaded.value) throw new Error('not loaded');

        if (password.value !== undefined) {
            if (data.value === null)
                rawData.value = null;
            else
                rawData.value = Encription.encrypt(serializer.write(data.value), password.value);
        }
    }, {
        deep: true
    })

    const setPassword = (_password: string) => {
        if (isEncrypted.value) throw Error('storage encrypted');

        password.value = _password;
    }

    const decrypt = (_password: string) => {
        if (rawData.value !== null) {
            const decoded = Encription.decrypt(rawData.value, _password);

            if (decoded === null) return false;

            data.value = serializer.read(decoded);
        }

        password.value = _password;

        return true;
    }

    const clear = () => {
        password.value = DEFAULT_PASSWORD;
        data.value = null;
    }

    const isEncrypted = computed(() => password.value === undefined && rawData.value !== null);
    const isEmpty = computed(() => rawData.value === null);

    watch(isLoaded, () => {
        if (isLoaded.value === true)
            decrypt(DEFAULT_PASSWORD);
    }, { once: true })

    return {
        decrypt,
        setPassword,
        clear,
        isLoaded,
        isEncrypted,
        isEmpty,
        data
    }
}