import { useEncryptedStorage } from '@/composables/useStorageEncrypted';
import { useTelegram } from '@/composables/useTelegram';
import { CHAINS, type TChain } from '@/constants';
import { useStorage } from '@vueuse/core';
import type { UserData } from '@waves/signer';
import { signTx, seedUtils, type TTxParams, type WithTxType } from '@waves/waves-transactions';
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, watch, watchEffect } from 'vue'
import * as libCrypto from '@waves/ts-lib-crypto';
import { type SignedTransaction, type Transaction, type WithId } from '@waves/ts-types';
import { SetSerializer } from '@/utils/serializer';
import { useWalletCloudStore } from './useWalletCloudStore';
import type { AssetAmount } from '@/libs/asset-amount';
import type { Asset } from '@/libs/asset';


const useStorePrivateMnemonicPhrases = defineStore('wallet-private-mnemonic-phrases', () => {
    const mnemonicPhrasesColdStorage = useEncryptedStorage<Set<string>>('mnemonic_phrases', {
        storage: localStorage,
        serializer: SetSerializer,
    });

    return {
        ...mnemonicPhrasesColdStorage
    }
})

export const useWalletStore = defineStore('wallet', () => {
    const coldStorage = useStorePrivateMnemonicPhrases();
    const cloudStore = useWalletCloudStore();

    const { isLoaded, publicKeys: cloudPublicKeys } = storeToRefs(cloudStore);

    const chain = useStorage<TChain>('chain', 'W');
    const publicKey = useStorage<string | null>('selected_wallet_public_key', null);

    const switchChain = (to: TChain | number) => {
        if (typeof to === 'number') {
            const networkByte = String.fromCharCode(to);

            chain.value = networkByte as TChain;
        } else {
            chain.value = to;
        }
    };

    const addWallet = (mnemonicPhrase: string) => {
        const seed = seedUtils.Seed.fromExistingPhrase(mnemonicPhrase);

        if (coldStorage.data)
            coldStorage.data.add(mnemonicPhrase);
        else
            coldStorage.data = new Set([mnemonicPhrase]);

        publicKey.value = seed.keyPair.publicKey;
    };

    const removeWallet = (mnemonicPhrase: string) => {
        if (!coldStorage.data) throw new Error('storage not loaded or empty')

        const newMnemonicPhrase = Array.from(coldStorage.data.values())[0];
        const newSeed = newMnemonicPhrase ? seedUtils.Seed.fromExistingPhrase(newMnemonicPhrase) : null;

        //clear password
        if (coldStorage.data.size === 1) {
            coldStorage.clear();
        } else {
            coldStorage.data.delete(mnemonicPhrase);
        }

        publicKey.value = newSeed?.keyPair.publicKey || null;
    };

    const addWallets = (mnemonicPhrases: string[]) => {
        if (!coldStorage.isLoaded) throw new Error('not loaded');

        coldStorage.data = new Set([...coldStorage.data || [], ...mnemonicPhrases]);
        publicKey.value = seedUtils.Seed.fromExistingPhrase(mnemonicPhrases[0]).keyPair.publicKey;
    };

    const mnemonicPhrases = computed(() => {
        return coldStorage.data ? [...coldStorage.data] : [];
    })

    const seedByPublicKey = computed<Record<string, seedUtils.Seed>>(() => {
        const entries = mnemonicPhrases.value.map(mnemonicPhrase => {
            const seed = seedUtils.Seed.fromExistingPhrase(mnemonicPhrase)

            return [
                seed.keyPair.publicKey, seed
            ]
        })

        return Object.fromEntries(entries)
    })

    const seed = computed(() =>
        publicKey.value ? seedByPublicKey.value[publicKey.value] : Object.values(seedByPublicKey.value)[0]
    );

    const mnemonicPhrase = computed(() =>
        seed.value?.phrase || null
    );

    const calcUserData = (chainId: string | number | undefined): UserData => {
        if (!mnemonicPhrase.value || !publicKey.value) throw new Error('not login');

        return {
            address: libCrypto.address(mnemonicPhrase.value, chainId),
            publicKey: publicKey.value,
        }
    }

    const isLogin = computed(() => {
        return !!seed.value
    })

    const chainId = computed(() => {
        return chain.value.charCodeAt(0);
    })

    const chainDetails = computed(() => {
        return CHAINS[chain.value];
    })

    const sign = (
        tx: (TTxParams & WithTxType) | Transaction
    ) => {
        if (!mnemonicPhrase.value) throw new Error('not login')

        return signTx(
            tx,
            mnemonicPhrase.value
        ) as SignedTransaction<Transaction> & WithId
    }

    const address = computed(() => {
        return seed.value ? libCrypto.address(seed.value.phrase, chain.value) : null
    });

    const haveUnsyncWallets = computed(() => {
        if (!cloudPublicKeys.value) return false;

        const publicKeysCold = Object.keys(seedByPublicKey.value);

        for (const publicKeyCloud of cloudPublicKeys.value.values()) {
            if (!publicKeysCold.includes(publicKeyCloud)) return true;
        }

        return false
    })

    const loadFromCloud = () => {
        if (!cloudStore.mnemonicPhrases) throw new Error('not loaded');

        addWallets(Array.from(cloudStore.mnemonicPhrases));
    }

    return {
        chain,
        publicKey,
        cloudPublicKeys,

        isLoaded,
        isEncrypted: storeToRefs(coldStorage).isEncrypted,
        isLogin,
        address,
        chainId,
        chainDetails,
        seed,
        haveUnsyncWallets,

        decrypt: coldStorage.decrypt,
        clear: coldStorage.clear,
        setPassword: coldStorage.setPassword,

        addWallets,
        addWallet,
        removeWallet,
        switchChain,
        sign,
        calcUserData,
        loadFromCloud
    }
});