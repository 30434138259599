import { Asset } from '@/libs/asset';
import { useWalletStore } from '@/stores/useWalletStore';
import { reactive } from 'vue';
import { defineStore } from 'pinia';
import type { TChain } from '@/constants';
import { assetIdToString } from '@/utils';


export const useAssetStore = defineStore('asset', () => {
    const walletStore = useWalletStore();

    const assets: Record<TChain, Record<string, Asset>> = reactive({})

    const getAsset = (assetId: string | null) => {
        return assets[walletStore.chain]?.[assetIdToString(assetId)];
    }

    const setAsset = (asset: Asset) => {
        if (!assets[walletStore.chain]) assets[walletStore.chain] = {}

        assets[walletStore.chain][assetIdToString(asset.assetId)] = asset;
    }

    const getAssets = (assetIds: (string | null)[]) => {
        if (!assets[walletStore.chain]) return [];

        return Object.values(assets[walletStore.chain]).filter((asset) => assetIds.includes(asset.assetId));
    }

    const setAssets = (_assets: Asset[]) => {
        for (const asset of _assets) {
            setAsset(asset)
        }
    }

    return { assets, getAsset, setAsset, getAssets, setAssets }
});