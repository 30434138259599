export type TChain = 'W' | 'T' | string;

export interface IChain {
    symbol: TChain,
    title: string,
    node: string,
    tx(id: string): string;
}

export const CHAINS: Record<TChain, IChain> = {
    'W': {
        symbol: 'W',
        title: 'Mainnet',
        node: 'https://nodes.wavesnodes.com',
        tx: (id: string) => {
            return `https://wavesexplorer.com/ru/transactions/${id}`
        }
    },
    'T': {
        symbol: 'T',
        title: 'Testnet',
        node: 'https://nodes-testnet.wavesnodes.com',
        tx: (id: string) => {
            return `https://wavesexplorer.com/ru/transactions/${id}?network=testnet`
        }
    }
}

export const WEBAPP_API_MIN_VERSION = '6.9'