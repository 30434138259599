<template>
	<div class="container">
		<AssetAvatar :asset="props.assetAmount.asset" class="avatar" />

		<div class="body">
			<div class="body__column">
				<div>{{ props.assetAmount.asset.name }}</div>
				<div>{{ (props.assetAmount.asset.price || 0).toFixed(2) }}$</div>
			</div>

			<div class="body__column text-right">
				<div>{{ props.assetAmount.amountToString() }}</div>
				<div>{{ amountInUsd.toFixed(2) }}$</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { AssetAmount } from '@/libs/asset-amount';
import AssetAvatar from './AssetAvatar.vue';

const props = defineProps<{
	assetAmount: AssetAmount;
}>();

const amountInUsd = computed(() => (props.assetAmount.asset.price || 0) * props.assetAmount.amountToFloat());
</script>

<style scoped>
.container {
	display: flex;
	align-items: center;
	padding: var(--size-s) var(--size-s);
	border-radius: var(--size-s);
	background-color: var(--secondary-background-color);

	font-size: 0.7em;
}

.body {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	margin-left: var(--size-m);

	font-size: var(--size-l);
}

.body__column {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	gap: var(--size-xxs);
}

.body__column div:first-child {
	font-weight: 500;
	font-size: 0.9em;
}

.body__column div:last-child {
	font-size: var(--size-s);
	color: var(--hint-color);
}

/* .token-amount span:first-child {
	font-weight: bold;
	font-size: 1em;
} */

.avatar {
	height: var(--size-xxxl);
	aspect-ratio: 1;
}

.token-amount span:last-child {
	/* color: var(--tg-theme-hint-color); */
}
</style>
