import { BackendApi } from '@/api/backend'
import { useTelegram } from '@/composables/useTelegram';


export const useBackendApi = () => {
    const { telegram } = useTelegram();

    return new BackendApi(import.meta.env.VITE_BACKEND_URL, {
        tg: telegram.WebApp.initData,
        'Content-Type': 'application/json'
    });
}