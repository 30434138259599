import { NodeApi } from '@/api/node';
import { useWalletStore } from '@/stores/useWalletStore';
import { shallowReactive, watch } from 'vue';

export const useNodeApi = () => {
    const walletStore = useWalletStore();
    const nodeApi = shallowReactive(new NodeApi(walletStore.chainDetails.node))

    watch(() => walletStore.chainDetails.node, () => {
        nodeApi.setBaseUrl(walletStore.chainDetails.node);
    })

    return nodeApi;
}