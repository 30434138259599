<template>
	<AssetItem :asset="selectedAssetAmount.asset" class="pointer" @click="modal.show()">
		<ChevronDownIcon class="chevron" />
	</AssetItem>
</template>

<script setup lang="ts">
import { defineModel, defineProps } from 'vue';
import { Asset, WAVES } from '@/libs/asset';
import { AssetAmount } from '@/libs/asset-amount';
import AssetItem from '@/components/AssetItem.vue';
import AssetAmountItem from '@/components/AssetAmountItem.vue';
import SelectAssetAmountModal from '@/components/modals/SelectAssetAmountModal.vue';
import { useModal } from '@/stores/useModalStore';
import ChevronDownIcon from '@/assets/icons/chevron-down.svg';

const props = defineProps<{
	assetAmounts: AssetAmount[];
}>();

const selectedAssetAmount = defineModel<AssetAmount>({
	default: new AssetAmount(new Asset(WAVES), 0)
});

const modal = useModal({
	component: SelectAssetAmountModal,
	props: {
		assetAmounts: props.assetAmounts
	},
	onClose(assetAmount?: AssetAmount) {
		if (assetAmount) selectedAssetAmount.value = assetAmount;
	}
});
</script>

<style scoped>
.chevron {
	min-width: var(--size-m);
	min-height: var(--size-m);

	max-width: var(--size-m);
	max-height: var(--size-m);

	margin-left: auto;

	transform: scale(1.4);
}
</style>
