import { computed, reactive, ref, type Component, type EmitsOptions, type Slots } from 'vue';

export interface IUseModalParams<T = any> {
    component: Component<T, any, any, any, any, {
        'closed': (...args: any[]) => any
    }>
    props?: T;
    slots?: Slots,
    isShow?: boolean;
    onClose?: (...params: any[]) => any;
    fullscreen?: boolean

    //(e: 'closed', result: boolean): any;
}

export const modals = ref<IUseModalParams[]>([]);
export const isShow = computed(() => !!modals.value.find(e => e.isShow))

export const useModalStore = () => {
    return {
        modals, isShow
    }
}

export const useModal = (_params: IUseModalParams) => {
    const params = reactive(_params)

    modals.value.push(params);

    const show = () => {
        params.isShow = true;
    }

    const hide = () => {
        params.isShow = false;
    }

    const destroy = () => {
        //modals.value.indexOf(params)
    }

    return {
        show, hide, destroy
    }
}