<template>
	<div v-if="!walletStore.isLoaded">
		<div class="loading-animation"></div>
	</div>

	<TheInputPassword v-else-if="walletStore.isEncrypted" />
	<slot v-else></slot>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useSignTransactionsStore } from '@/stores/useSignTransactionsStore';
import { useModalStore, useModal } from '@/stores/useModalStore';
import { ref, watch, reactive, watchEffect } from 'vue';
import TheSignTransactionModal from '@/components/modals/SignTransactionModal.vue';
import { useTelegram } from '@/composables/useTelegram';
import { useWalletStore } from '@/stores/useWalletStore';
import TheInputPassword from '@/components/TheInputPassword.vue';
import { storeToRefs } from 'pinia';
import { useWalletCloudStore } from '@/stores/useWalletCloudStore';
import { removeFocus } from '@/utils';

// const props = defineProps<{ onlyChain: string[] }>();

const { telegram } = useTelegram();
const router = useRouter();

const signTransactionsQueue = useSignTransactionsStore();
const modalStore = useModalStore();
const walletStore = useWalletStore();

watchEffect(() => {
	if (walletStore.haveUnsyncWallets === true && walletStore.isLoaded === true && walletStore.isEncrypted === false) {
		telegram.WebApp.showConfirm('You have wallets in cloud. Load it?', (ok) => {
			if (ok)
				useWalletCloudStore().decryptBefore(() => {
					walletStore.loadFromCloud();
				});
		});
	}
});

/** BackButton logic **/
const backButtonProcess = () => {
	if (history.state.back) {
		telegram.WebApp.BackButton.show();
	} else {
		telegram.WebApp.BackButton.hide();
	}
};

window.Telegram.WebApp.onEvent('backButtonClicked', () => router.back());

watch(modalStore.isShow, () => {
	if (modalStore.isShow.value) telegram.WebApp.BackButton.hide();
	else backButtonProcess();
});

router.afterEach(() => backButtonProcess());

/** SignQueue logic **/

const signTransactionModal = useModal({
	component: TheSignTransactionModal,
	props: {
		txRequest: reactive(signTransactionsQueue.nextTransaction)
	},
	onClose: () => {
		signTransactionsQueue.nextTransaction.value?.reject('rejected by user');
	}
});

watch(
	signTransactionsQueue.nextTransaction,
	() => {
		if (signTransactionsQueue.nextTransaction.value) signTransactionModal.show();
	},
	{ immediate: true }
);
</script>
