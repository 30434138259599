<template>
	<img :src="icon" />
</template>

<script setup lang="ts">
import * as avatar from 'identity-img';

const props = defineProps<{
	address: string;
}>();

const icon = avatar.create(props.address, { size: 67 * 3 });
</script>
