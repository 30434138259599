import type { Serializer } from '@vueuse/core'

export const SetSerializer: Serializer<Set<string>> = {
    read(raw) {
        return new Set(JSON.parse(raw))
    },
    write(value) {
        return JSON.stringify(Array.from(value))
    }
}

export const DefaultSerializer: Serializer<any> = {
    read(raw) {
        return raw;
    },
    write(value) {
        return value;
    }
}