<template>
	<div class="asset-item">
		<AssetAvatar :asset="props.asset" />
		<div>
			<span>{{ props.asset.name }}</span>
		</div>
		<slot />
	</div>
</template>

<script setup lang="ts">
import AssetAvatar from './AssetAvatar.vue';
import { type IAsset } from '@/libs/asset';

const props = defineProps<{
	asset: IAsset;
}>();
</script>

<style scoped>
.asset-item {
	display: flex;
	align-items: center;
	padding: var(--size-s);
	border-radius: var(--size-xs);
	background-color: var(--secondary-background-color);
	overflow: hidden;
}

.asset-item div {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}

.asset-item span {
	font-size: 0.7em;
	text-wrap: nowrap;
	/* text-overflow: initial; */
}

.asset-item span:first-child {
	font-weight: 500;
	font-size: 1em;
}

.asset-item img {
	height: 1em;
	aspect-ratio: 1;
}

.token-amount span:last-child {
	/* color: var(--tg-theme-hint-color); */
}
</style>
