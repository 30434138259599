import type { SignedTx, SignerTx } from '@waves/signer';
import { makeTx, type WithSender } from '@waves/waves-transactions';
import { type TTxParamsWithType } from '@waves/waves-transactions/dist/make-tx';
import { type TTransactionType } from '@waves/waves-transactions/dist/transactions';
import type { MaybePromise } from 'rollup';
import { computed, reactive, readonly, ref } from 'vue';
import { type SignedTransaction, type Transaction, type WithId } from '@waves/ts-types';

type TTx<T extends TTransactionType> = TTxParamsWithType<T> & WithSender;

export interface ISignTransaction {
    resolve: (value: SignedTx<SignerTx>) => void,
    reject: (reason?: any) => void,
    tx: SignerTx,

    callback?: (value: (SignedTransaction<Transaction> & WithId)) => any | Promise<any>,
    broadcast?: boolean,
}

const signTransactionsQueue = ref<ISignTransaction[]>([]);

export const useSignTransactionsStore = () => {
    const sign = async (tx: SignerTx, broadcast?: boolean): Promise<SignedTx<SignerTx>> => {
        return new Promise((resolve, reject) => {
            const resolveHandler = (value: SignedTx<SignerTx>) => {
                signTransactionsQueue.value.shift();
                resolve(value);
            }
            const rejectHandler = (reason?: any) => {
                signTransactionsQueue.value.shift();
                reject(reason);
            }

            signTransactionsQueue.value.push({
                resolve: resolveHandler,
                reject: rejectHandler,
                tx,
                broadcast
            })
        })
    }

    const nextTransaction = computed<ISignTransaction | undefined>(() => {
        return signTransactionsQueue.value[0];
    })

    return {
        signTransactionsQueue: readonly(signTransactionsQueue),
        nextTransaction,
        sign
    }
}