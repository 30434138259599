import { ApiInstance } from '../api-instance';
import type { IAddressBalanceResponse, IAssetsBalanceResponse, IAssetsDetailsResponse } from './types';

export const MAX_ASSETS_PER_REQUEST = 100;

export class NodeApi extends ApiInstance {
    Assets = {
        balance: (address: string): Promise<IAssetsBalanceResponse> => {
            return this.get(`/assets/balance/${address}`)
        },

        details: ((assetId: string | string[]) => {
            if (!Array.isArray(assetId)) {
                return this.get(`/assets/details/${assetId}`);
            }

            return this.post(`/assets/details`, { ids: assetId });
        }) as {
            (assetId: string): Promise<IAssetsDetailsResponse>;
            (assetId: string[]): Promise<IAssetsDetailsResponse[]>;
        }
    }
    Address = {
        balance: (address: string): Promise<IAddressBalanceResponse> => {
            return this.get(`/addresses/balance/${address}`)
        }
    }
}