import { NodeApi } from '@/api/node';
import { Asset, WAVES } from '@/libs/asset';
import { useAssetStore } from '@/stores/useAssetStore'
import { useWalletStore } from '@/stores/useWalletStore';
import { useBackendApi } from './useBackendApi';
import { assetIdToString } from '@/utils';
import { useNodeApi } from './useNodeApi';


export const useAsset = async (assetId: string | null) => {
    const nodeApi = useNodeApi();
    const assetStore = useAssetStore();
    const backendApi = useBackendApi();

    const asset = assetStore.getAsset(assetId);

    console.log(asset)

    if (!asset) {
        const assetIdString = assetIdToString(assetId);

        const [rawAssetBase, rawAssetInfo] = await Promise.all([
            assetId ? await nodeApi.Assets.details(assetIdString) : WAVES,
            await backendApi.Assets.info({ assets: [assetIdString] })
        ]);

        assetStore.setAsset(new Asset({ ...rawAssetBase, icon: rawAssetInfo[assetIdString]?.logo, price: rawAssetInfo[assetIdString]?.rate }));
    };

    return assetStore.getAsset(assetId);
}