<template>
	<h1>Transfer</h1>

	<SignTransactionLogo>
		<template #image>
			<AssetAvatar :asset="asset" />
		</template>

		<template #sub-image>
			<ArrowUpIcon />
		</template>
	</SignTransactionLogo>

	<Section>
		<SectionLine>
			<div class="hint">Asset</div>

			<AssetItem :asset="asset" class="asset" />
		</SectionLine>

		<SectionLine>
			<div class="hint">Amount</div>

			<div>{{ asset.amountToString(tx.amount) }}</div>
		</SectionLine>

		<SectionLine>
			<div class="hint">Recipient</div>

			<!-- <div class="flex f-align-center">
				<div>{{ formatAddress(tx.recipient) }}</div>
				<CopyIcon class="icon" />
			</div> -->

			<div>{{ formatAddress(tx.recipient, 8) }}</div>
		</SectionLine>

		<slot />
	</Section>
</template>

<script setup lang="ts">
import { type TransferTransaction } from '@waves/ts-types';
import AssetItem from '@/components/AssetItem.vue';
import { useAsset } from '@/services/useAsset';
import Section from '@/components/ui/Section.vue';
import { formatAddress } from '@/utils';
import SectionLine from '@/components/ui/SectionLine.vue';
import CopyIcon from '@/assets/icons/copy.svg?component';
import { useTelegram } from '@/composables/useTelegram';
import AssetAvatar from '@/components/AssetAvatar.vue';
import ArrowUpIcon from '@/assets/icons/arrow-up.svg?component';
import SignTransactionLogo from './SignTransactionLogo.vue';

const props = defineProps<{
	tx: TransferTransaction;
}>();

const { telegram } = useTelegram();
const asset = await useAsset(props.tx.assetId);

const copyRecipient = () => {
	navigator.clipboard.writeText(props.tx.recipient);
	telegram.WebApp.showAlert('Recipient copied to clipboard');
};
</script>

<style scoped>
.asset {
	padding: 0px;
}
</style>
