<template>
	<Page>
		<template #top>
			<InputSearch v-model="filter" />
		</template>

		<AssetAmountItem
			v-for="assetAmount of filteredAssetAmounts"
			:key="assetAmount.asset.assetId || 'WAVES'"
			:assetAmount="assetAmount"
			class="pointer"
			@click="emit('closed', assetAmount)"
		/>
	</Page>
</template>

<script setup lang="ts">
import { defineProps, ref, computed } from 'vue';
import { AssetAmount } from '@/libs/asset-amount';
import AssetAmountItem from '@/components/AssetAmountItem.vue';
import Page from '../ui/Page.vue';
import InputSearch from '../ui/InputSearch.vue';

const props = defineProps<{
	assetAmounts: AssetAmount[];
}>();

const filter = ref('');
const filteredAssetAmounts = computed(() =>
	props.assetAmounts.filter((assetAmount) =>
		assetAmount.asset.name.toLowerCase().includes(filter.value.toLowerCase())
	)
);

const emit = defineEmits(['closed']);
</script>

<style scoped></style>
