<template>
	<div class="container">
		<div v-if="$slots.image" class="intro-image">
			<slot name="image" />
		</div>

		<h1 v-if="$slots.header" class="header">
			<slot name="header" />
		</h1>

		<div v-if="$slots.top" class="top">
			<slot name="top" />
		</div>

		<div v-if="$slots.default" class="content">
			<slot />
		</div>

		<div v-if="$slots.footer" class="footer">
			<slot name="footer" />
		</div>
	</div>
</template>

<style scoped>
.container {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: var(--size-s);
	gap: var(--size-s);
	position: relative;
}

.header {
}

.top {
	margin-bottom: var(--size-m);
}

.content {
	display: flex;
	flex-direction: column;
	gap: var(--size-s);
	width: 100%;
}

.footer {
	display: flex;
	flex-direction: column;
	gap: var(--size-s);
	margin-top: auto;

	margin-bottom: var(--safe-area-inset-bottom);
}
</style>
