<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 50 50"
		xml:space="preserve"
	>
		<circle cx="25" cy="25" r="25" style="fill: #25ae88; stroke: none" />
		<polyline
			style="
				fill: none;
				stroke: #ffffff;
				stroke-width: 2;
				stroke-linecap: round;
				stroke-linejoin: round;
				stroke-miterlimit: 10;
			"
			points="
	38,15 22,33 12,25 "
		/>
	</svg>
</template>
