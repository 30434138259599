<template>
	<button
		:class="['button', props.variant, props.loading ? 'loading' : '']"
		:disabled="props.disabled || props.loading"
	>
		<slot />
	</button>
</template>

<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		loading?: boolean;
		disabled?: boolean;
		variant?: 'primary' | 'inline' | 'line' | 'link' | 'primary-line';
	}>(),
	{
		loading: false,
		disabled: false,
		variant: 'primary'
	}
);
</script>

<style scoped>
.button {
	border-radius: var(--size-s);
	border: none;
	padding: var(--size-s);
	cursor: pointer;
	/* display: flex;
	justify-content: space-around;
	flex-direction: column;
	align-items: center; */
	user-select: none;
	text-align: center;
	position: relative;
}

.primary {
	background-color: var(--button-background-color);
}

.primary-line {
	background-color: var(--secondary-background-color);
	padding: var(--size-xs) var(--size-s);
	color: var(--button-background-color);
}
/* 
.primary:hover {
	background-color: white;
} */

.inline {
	display: flex;
	flex-direction: row;
	width: auto;

	background-color: var(--secondary-background-color);
	border-radius: var(--size-s);

	color: var(--text-color);

	padding: var(--size-xs) var(--size-s);
}

.line {
	display: flex;
	flex-direction: row;

	background-color: var(--secondary-background-color);
	border-radius: 0px;
	justify-content: flex-start;

	color: var(--text-color);

	padding: var(--size-xs) var(--size-s);
}

.link {
	display: flex;
	flex-direction: row;

	background-color: unset;

	padding: var(--size-xs) var(--size-s);

	color: var(--text-color);
}

.line:not(:last-of-type) {
	border-bottom: 1px solid var(--section-separator-color);
}

.button:disabled {
	background-color: var(--hint-color);
	cursor: none;
	pointer-events: none;
}

.loading {
	position: relative;

	text-indent: -9999px;
}

.loading::after {
	visibility: visible;
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	border: 4px solid transparent;
	border-top-color: currentColor;
	border-radius: 50%;
	animation: spinner 1s ease infinite;
}

@keyframes spinner {
	from {
		transform: rotate(0turn);
	}

	to {
		transform: rotate(1turn);
	}
}
</style>
