import { createApp } from 'vue';
import App from '@/App.vue';
import AppDev from '@/AppDev.vue';
import { router } from '@/router';
import { Buffer } from 'buffer'
// import VueVirtualScroller from 'vue-virtual-scroller'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";

import '@/assets/styles/global.css';

globalThis.Buffer = Buffer

const app = import.meta.env.MODE === 'development' ? createApp(AppDev) : createApp(App);

Sentry.init({
    app,
    dsn: "https://d5ae532366afeb37940af1c1a920fdce@o4507408483024896.ingest.de.sentry.io/4508098672984144",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(createPinia());
app.use(router);
// app.use(VueVirtualScroller)
app.mount('#app');

app.config.errorHandler = (err, vm, info) => {
    console.error('errorHandler', err, vm, info)
}