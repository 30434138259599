<template>
	<div class="section"><slot></slot></div>
</template>

<style scoped>
.section {
	background-color: var(--secondary-background-color);

	border-radius: var(--size-s);

	overflow: hidden;
}

.section > * {
	padding: var(--size-xs) var(--size-s);
	min-height: var(--size-l);

	display: flex;
	align-items: center;
}

.section > *:not(:last-of-type) {
	border-bottom: 1px solid var(--section-separator-color);
}
</style>
