import { Asset } from '@/libs/asset';
import { AssetAmount } from '@/libs/asset-amount';
import { ref, watch } from 'vue'
import { useWalletStore } from '@/stores/useWalletStore';
import { defineStore, storeToRefs } from 'pinia';
import { useBalance } from '@/services/useBalance';


export const useBalanceStore = defineStore('balance', () => {
    const { address } = storeToRefs(useWalletStore());

    //useAsyncState
    const assetAmounts = ref<AssetAmount<Asset>[] | null>(null);

    const updateAssetAmounts = async () => {
        if (address.value) {
            assetAmounts.value = await useBalance(address.value);
        }
        else
            assetAmounts.value = []
    }

    watch(
        address,
        () => {
            assetAmounts.value = null;

            updateAssetAmounts();
        },
        {
            immediate: true
        }
    );

    return {
        assetAmounts, updateAssetAmounts
    }
});