<template>
	<div class="transaction-logo">
		<div class="transaction-logo__image">
			<slot name="image" />
		</div>
		<div class="transaction-logo__sub-image">
			<slot name="sub-image" />
		</div>
	</div>
</template>

<style scoped>
.transaction-logo {
	position: relative;

	aspect-ratio: 1;
	width: 35%;

	margin: 0 auto;
}

.transaction-logo__image > * {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.transaction-logo__sub-image > * {
	position: absolute;
	bottom: 0px;
	right: 0px;

	height: 30%;

	outline: solid 5px var(--background-color);

	/* aspect-ratio: 1; */

	stroke: var(--button-text-color);
	background-color: var(--destructive-text-color);
	border-radius: 50%;
}
</style>
