import { DefaultSerializer } from '@/utils/serializer';
import type { Serializer, StorageLike, StorageLikeAsync } from '@vueuse/core';
import { ref, watch, type Ref } from 'vue';

interface IUseStorageOptions<T> {
    storage?: StorageLike | StorageLikeAsync,
    deep?: boolean,
    serializer?: Serializer<T>,
    defaults?: T
}

interface IUseStorageLoading {
    data: Ref<null>,
    isLoaded: Ref<false>,
}

interface IUseStorageLoaded<T> {
    data: Ref<null | T>,
    isLoaded: Ref<true>,
}

type TUseStorage<T> = IUseStorageLoading | IUseStorageLoaded<T>;

export const useStorage = <T>(key: string, options: IUseStorageOptions<T> = {}) => {
    const {
        storage = window.localStorage,
        deep = true,
        serializer = DefaultSerializer,
        defaults
    } = options;

    const data = ref(null) as Ref<T | null>;
    const isLoaded = ref(false) as Ref<boolean>;

    (async () => {
        const item = await storage.getItem(key);

        if (item !== null)
            data.value = serializer.read(item);
        else if (defaults !== undefined)
            data.value = defaults;

        watch(data, (newData) => {
            if (newData === null)
                storage.removeItem(key);
            else
                storage.setItem(key, serializer.write(newData));
        }, { deep })

        isLoaded.value = true;
    })();

    return { data, isLoaded } as TUseStorage<T>;
}