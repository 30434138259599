import { type StorageLikeAsync } from '@vueuse/core';

export const useTelegram = () => {
    const telegram = window.Telegram;

    const cloudStorage: StorageLikeAsync = {
        getItem(key) {
            return new Promise((resolve, reject) => {
                telegram.WebApp.CloudStorage.getItem(key, (error, value) => {
                    if (error) return reject(error);
                    else return resolve(value === '' ? null : value);
                })
            })
        },
        removeItem(key) {
            return new Promise((resolve, reject) => {
                telegram.WebApp.CloudStorage.removeItem(key, (error) => {
                    if (error) return reject(error);
                    else return resolve();
                })
            })
        },
        setItem(key, value) {
            return new Promise((resolve, reject) => {
                telegram.WebApp.CloudStorage.setItem(key, value, (error) => {
                    if (error) return reject(error);
                    else return resolve();
                })
            })
        },
    }

    return {
        telegram, cloudStorage
    }
}