import { ApiInstance } from '../api-instance';
import type { IInfoParams, TInfoResponse, IApproveParams, IRejectParams, ISignParams, IGetParams, IApp } from './types';


export class BackendApi extends ApiInstance {
    Assets = {
        info: (params: IInfoParams): Promise<TInfoResponse> => {
            return this.post(
                '/v2/assets/info',
                params,
            );
        }
    }
    Appcatalog = {
        apps: (): Promise<IApp[]> => {
            return this.get(
                '/v2/appcatalog/apps'
            );
        }
    }
    Connection = {
        approve: (params: IApproveParams) => {
            return this.post(
                '/v2/connection/approve',
                params,
            );
        },
        reject: (params: IRejectParams) => {
            return this.post(
                '/v2/connection/reject',
                params,
            );
        }
    }
    Transaction = {
        sign: (params: ISignParams) => {
            return this.post(
                '/v2/transaction/sign',
                params,
            );
        },
        reject: (params: IRejectParams) => {
            return this.post(
                '/v2/transaction/reject',
                params,
            );
        },
        get: (params: IGetParams) => {
            return this.get(
                `/v2/transaction/${params.id}`
            );
        }
    }
}