import { Asset, WAVES } from '@/libs/asset';
import { useAssetStore } from '@/stores/useAssetStore';
import { useBackendApi } from './useBackendApi';
import { useNodeApi } from '@/services/useNodeApi';
import { MAX_ASSETS_PER_REQUEST } from '@/api/node';

export const useAssets = async (assetIds: (string | null)[]) => {
    const assetStore = useAssetStore();
    const nodeApi = useNodeApi();
    const backendApi = useBackendApi();

    const assets = assetStore.getAssets(assetIds);

    const syncedAssetIds = assets.map(asset => asset.assetId);

    const unsyncedAssetIdsRaw = assetIds.filter(assetId => !syncedAssetIds.includes(assetId));

    if (unsyncedAssetIdsRaw.includes(null)) assetStore.setAsset(new Asset(WAVES));

    const unsyncedAssetIds = unsyncedAssetIdsRaw.filter(assetId => assetId !== null);

    for (let i = 0; i < unsyncedAssetIds.length; i += MAX_ASSETS_PER_REQUEST) {
        const [assetsDetails, assetsInfo] = await Promise.all([
            nodeApi.Assets.details(unsyncedAssetIds.slice(i, i + MAX_ASSETS_PER_REQUEST)),
            backendApi.Assets.info({ assets: unsyncedAssetIds.slice(i, i + MAX_ASSETS_PER_REQUEST) })
        ])

        assetStore.setAssets(assetsDetails.map((assetDetails) => {
            return new Asset({
                ...assetDetails,
                icon: assetsInfo[assetDetails.assetId]?.logo, price: assetsInfo[assetDetails.assetId]?.rate
            })
        }))
    }

    return assetStore.getAssets(assetIds);
}