<template>
	<TheSignTransaction :tx="txRequest.tx" :broadcast="txRequest.broadcast" @signed="signed" @rejected="rejected" />
</template>

<script setup lang="tsx">
import { type ISignTransaction } from '@/stores/useSignTransactionsStore';
import type { SignedTx, SignerTx } from '@waves/signer';
import TheSignTransaction from '@/components/transactions/TheSignTransaction.vue';
import { type Transaction, type SignedTransaction } from '@waves/ts-types';

const props = defineProps<{
	txRequest: ISignTransaction;
}>();

const signed = async (signedTx: SignedTransaction<Transaction>) => {
	props.txRequest.resolve(signedTx as SignedTx<SignerTx>);

	emit('closed');
};

const rejected = () => {
	props.txRequest.reject('rejected by user');
	emit('closed');
};

const emit = defineEmits(['closed']);
</script>
