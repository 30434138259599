<template>
	<code>{{ props.tx }}</code>
</template>

<script setup lang="ts">
import { type Transaction } from '@waves/ts-types';

const props = defineProps<{
	tx: Transaction;
}>();
</script>
