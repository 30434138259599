<template>
	<div class="line">
		<slot />
	</div>
</template>

<style scoped>
.line {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: var(--size-xl);
	text-align: justify;
}
</style>
