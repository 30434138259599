<template>
	<Page>
		<template #header>Unlock cloud</template>

		<template #default>
			<div>Input cloud password</div>
			<div v-if="result === false">incorrect password</div>
			<input v-model="password" />
		</template>

		<template #footer>
			<button @click="decrypt">Decrypt</button>
		</template>
	</Page>
</template>

<script setup lang="ts">
import { useWalletCloudStore } from '@/stores/useWalletCloudStore';
import { ref } from 'vue';
import Page from '../ui/Page.vue';

const walletCloudStore = useWalletCloudStore();

const password = ref();
const result = ref();

const decrypt = () => {
	result.value = walletCloudStore.decrypt(password.value);

	if (result.value === true) {
		emit('closed', true);
	}
};

const emit = defineEmits<{
	(e: 'closed', result: boolean): any;
}>();
</script>
