import nacl from 'tweetnacl';

const DEFAULT_NONCE = new Uint8Array(nacl.secretbox.nonceLength);

export class Encription {
    static encrypt(message: string, password: string, nonce?: Uint8Array) {
        const key = nacl.hash(new TextEncoder().encode(password)).slice(nacl.secretbox.keyLength);

        return Buffer.from(nacl.secretbox(new TextEncoder().encode(message), nonce || DEFAULT_NONCE, key)).toString('base64');
    }
    static decrypt(box: string, password: string, nonce?: Uint8Array) {
        const key = nacl.hash(new TextEncoder().encode(password)).slice(nacl.secretbox.keyLength);

        const opened = nacl.secretbox.open(Buffer.from(box, 'base64'), nonce || DEFAULT_NONCE, key);

        return opened === null ? null : new TextDecoder().decode(opened)
    }
}